<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import Delete from "../../../components/widgets/deleteForever.vue";
import belowNav from '../../../components/belowNavbar.vue'
export default {
  page: {
    title: "Trash",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout,
    Delete,
    belowNav },
  data() {
    return {
      title: "Trash",
      sortName : false
    }
  },
  computed: {
    listings(){
        return this.$store.getters['storage/items'];
    },
    loading(){
        return this.$store.getters['storage/loading'];
    },
  },
  mounted(){
    // this.$ga.page('/trash')
    this.$ga.event('trash-page', 'actionx', 'labelx', 1)
    this.$store.commit("storage/SET_ALL_SELECTED",[]);
    this.$store.commit("storage/SET_PARENT_ID","");
    this.$store.commit("storage/SET_SHOW_PROPERTIES",false);
    this.$store.commit("share/SET_SHOW_PROPERTIES",false);
    this.$store.commit("workgroup/SET_SHOW_PARTICIPANTS",false);
    const sideBarValue = this.$store.getters['storage/sideRightToggle'];
    this.$store.dispatch('storage/setSideRightToggle',{isShowing: sideBarValue, isPersistent: true});
    if(this.$store.getters["upload/files"].length > 0){
      document.getElementById("uploadLoading").style.display = "block";
    }else{
      document.getElementById("uploadLoading").style.display = "none";
    }
    this.$store.dispatch("storage/listing",1);
    this.$store.commit("storage/SET_SHOWBREADCUMB",false);
    this.$store.commit("storage/SET_SUBTITLE","Trash");
  },
  methods:{
      hapus(item){
        this.$store.commit("storage/SET_ITEM",item);
        this.$root.$emit('bv::show::modal', 'modalDeleteForever');
        this.$store.dispatch("storage/fileretrieve",{_id : item._id,directory:item.directory})
      },
      restore(item){
        this.$store.dispatch("storage/restore",item._id)
        .then(()=> {
          this.$toasted.show(`Item restored successfully.`,{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : 2000
            })
        })
        .catch(()=> {
          this.$toasted.show(`Item failed to restore. Make sure your internet is connected, then try again.`,{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : 2000
            })
        })
      }
  }
};
</script>
<style>
</style>
<template>
  <Layout>
    <belowNav/>
    <!-- start page title -->
    <div class="row">
        <Delete/>
        <!-- <div class="col-md-6">
            <b-alert variant="danger" show>
                <img src="@/assets/images/icon/Warning.png" alt height="100%" />
                <span class="mx-2">Items in trash will be deleted forever after 30 days.</span>
            </b-alert>
        </div> -->
      <div class="col-12 all-pages">
            <table class="table table-storage default-text-style">
                <thead>
                    <th width="55%" class="default-text-style">
                        <span class="text-muted">Name</span> 
                        <i v-if="!sortName" class="bx bx-caret-up"></i>
                        <i v-else class="bx bx-caret-down"></i>
                    </th>
                    <th width="23%" class="default-text-style table-web-display"><span class="text-muted">Trashed date</span></th>
                    <th width="20%" class="default-text-style table-web-display"><span class="text-muted">File Size</span></th>
                    <th width="2%" class="text-center"></th>
                </thead>
                <!-- <Sekeleton v-if="loading"/> -->
                <tbody v-if="listings.length > 0 && !loading">
                    <tr v-for="(list,index) in listings" :key="index">
                      <!-- <pre>{{list}}</pre> -->
                        <td class="align-items-center">
                            <div class="d-flex align-items-center py-2">
                                <div class="file-name">
                                  <img :src="require(`@/assets/images/icon/${list.icon}`)" alt />
                                  <span v-if="!list.isEdit"  class="mx-2">{{list.file_name}}</span>
                                  <b-form v-else >
                                      <b-form-group id="input-group-1" class="form-group-rename" label-class="label-input" label-for="input-1">
                                          <b-input-group>
                                              <!-- <b-input-group-append>
                                                  <img :src="require(`@/assets/images/icon/${list.icon}`)" alt />
                                              </b-input-group-append> -->
                                              <b-form-input id="input-1" autofocus class="login-input-style" v-model="valueRename" type="text" placeholder="Folder New"></b-form-input>
                                              <b-input-group-append>
                                                  <b-button @click="submitRename(index)" class="btn-on-input" v-b-tooltip.hover title="Save" ><i class="dripicons-checkmark text-success"></i></b-button>
                                                  <b-button @click="rename(index)" class="btn-on-input" v-b-tooltip.hover title="Cancel" ><i class="dripicons-cross text-danger"></i></b-button>
                                              </b-input-group-append>
                                          </b-input-group>
                                      </b-form-group>
                                  </b-form>
                                </div>
                            </div>
                        </td>
                        <td  class="">
                           <p> - </p>
                           <!-- <p> {{list.datemodified}}</p> -->
                        </td>
                        <td  class="">
                            <p>{{list.size}}</p>
                        </td>
                        <td>
                            <div>
                                <b-dropdown  right class="bg-transparent more-menu">
                                    <template #button-content>
                                        <img src="@/assets/images/icon/More.png" alt />
                                    </template>
                                    <b-dropdown-item-button @click="restore(list)">
                                        <img src="@/assets/images/icon/Restore.png" alt height="100%" />
                                        <span class="ml-3">Restore</span>
                                    </b-dropdown-item-button>
                                    <b-dropdown-item-button @click="hapus(list)">
                                        <img src="@/assets/images/icon/Delete.png" alt height="100%" />
                                        <span class="ml-3">Delete Forever</span>
                                    </b-dropdown-item-button>
                                </b-dropdown>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        <div v-if="listings.length <= 0 && !loading" class="d-flex flex-column justify-content-center align-items-center">
            <span class="mt-2"></span><br>
            <img src="@/assets/images/empty-trash.svg" alt class="img-fit mb-4" />
            <p class="mb-0 pb-0 title-empty mb-2">No deleted files yet</p>
            <p class="text-empty pt-0 mt-0">Files in the trash can be restored or deleted permanently.</p>
        </div>
      </div>
    </div>
    <!-- end page title -->
  </Layout>
</template>
