
<script>
export default {
    computed:{
        item(){
            return this.$store.getters["storage/item"];
        },
    },
    data() {
        return {
            folderName: "Folder New",
            invalidFolderName : "Your account or Email is incorrect.",
            validFolderName : "",
            stateFolderName : true,
            counter : 0
        };
    },
    methods:{
      cancel(){
          this.$root.$emit('bv::hide::modal', 'modalDeleteForever');
      },
      deleteForever(id){
        this.$store.dispatch("storage/deleteForever",id)
        .then(()=> {
          this.$toasted.show(`Item deleted permanently.`,{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : 2000
            })
        })
        .catch(()=> {
          this.$toasted.show(`Item failed to delete permanently. Make sure your internet is connected, then try again.`,{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : 2000
            })
        })
        this.$root.$emit('bv::hide::modal', 'modalDeleteForever');
      }
    }
};
</script>
<template>
    <b-modal id="modalDeleteForever" no-fade  content-class="shadow" :hide-header="true"  :hide-footer="true"  centered title="Delete Forever ?">
        <div v-if="item != null">
            <h2 class="modal-title text-dark default-text-style">Delete from Trash? ⏳</h2>
            <p class="mt-3 default-text-style">"{{item.file_name}}" will be deleted forever and you cannot recover it.</p>
            <div class="d-flex justify-content-end align-items-center">
                <a href="javascript:void();" @click="cancel()" class="default-text-style"><span class="text-blue">Cancel</span></a>
                <button class="btn base-btn btn-storage mx-4 " @click="deleteForever(item._id)">
                    <span class="default-text-style">Delete Forever</span>
                </button>
            </div>
        </div>
    </b-modal>
</template>