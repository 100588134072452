<template>
  <b-modal id="modal-multiple-copy" no-close-on-backdrop hide-header hide-footer centered>
    <div class="mb-3">
        <span class="title-modal-m-copy">Copy to</span>
    </div>
    <div class="options-multiple-v3 py-3">
        <div class="mb-3">
            <input type="radio" id="personal-m-copy" :value="true" v-model="isPersonal">
            <label for="personal-m-copy"><span><span></span></span>Personal</label>
        </div>
        <div v-if="state == 'workgroup' && selectedWorkgroup.type != 'PRIVATE'">
            <input type="radio" id="workgroup-m-copy" :value="false" v-model="isPersonal">
            <label for="workgroup-m-copy"><span><span></span></span>Workgroup</label>
        </div>
        <div v-else-if="state == 'storage'">
            <input type="radio" id="workgroup-m-copy" :value="false" v-model="isPersonal">
            <label for="workgroup-m-copy"><span><span></span></span>Workgroup</label>
        </div>
    </div>
    <div class="footer-multiple-copy-v3 mt-3">
        <button class="btn-cancel-multiple-v3" @click="close()">Cancel</button>
        <button :disabled="isPersonal == null" class="ml-3 btn-multiple-v3" @click="isPersonal ? copyToPersonal() : copyToWorkgroup()">Copy</button>
    </div>
  </b-modal>
</template>

<script>
export default {
    name: 'ModalMultipleCopy',
    data() {
        return {
            isPersonal: null,
        }
    },
    computed: {
        state() {
            return this.$store.getters['state/state']
        },
        selectedField() {
            return this.$store.getters['storage/selected']
        },
        selectedWorkgroup() {
            return this.$store.getters['workgroup/workgroup']
        }
    },
    methods: {
        close() {
            this.$bvModal.hide('modal-multiple-copy')
            this.isPersonal = null
        },
        copyToPersonal() {
            this.$store.commit("storage/SET_ITEM",this.selectedField);
            this.$store.commit("storage/SET_HASHMOVE",this.selectedField.hash_name);
            this.$store.commit("storage/SET_KEYWORD","");
            this.$store.commit("storage/SET_PARENT_ID","");
            this.$store.commit("storage/DELETE_BREADCUMB",[]);
            this.$store.dispatch("storage/listing", 'noclear')
            this.$bvModal.hide('modal-multiple-copy')
            this.$root.$emit('bv::show::modal', 'modalCopyPersonal')
            this.isPersonal = null
        },
        copyToWorkgroup() {
            if(this.state == 'storage') {
                this.$bvModal.hide('modal-multiple-copy')
                this.$root.$emit('bv::show::modal', 'modalCopyWorkgroup')
                this.$store.commit("workgroup/SET_SELECTED", this.selectedField)
                this.isPersonal = null
            } else {
                this.$bvModal.hide('modal-multiple-copy')
                this.$root.$emit('bv::show::modal', 'modalCopy')
                this.$store.commit("workgroup/SET_SELECTED", this.selectedField)
                this.isPersonal = null
            }
        }
    }
}
</script>

<style scoped>
.title-modal-m-copy{
    font-size: 16px;
    line-height: 24px;
    color: #262A2C;
    letter-spacing: -1px;
    font-weight: 400;
}
.options-multiple-v3{
    display: flex;
    flex-direction: column;
}
.footer-multiple-copy-v3{
    display: flex;
    justify-content: flex-end;
}
.btn-multiple-v3{
    background: #00AAFF;
    color: #FFFFFF;
    border: none;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1px;
    font-weight: 400;
    padding: 8px 16px;
    border-radius: 4px;
}
.btn-multiple-v3:disabled{
    background: #E9EAEA;
    color: #919394;
}
.btn-cancel-multiple-v3{
    background: none;
    color: #00AAFF;
    border: none;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1px;
    font-weight: 400;
}
.btn-cancel-multiple-v3:focus{
    background: none;
}
input[type='radio'] {
    -webkit-appearance:none;
    width:16px;
    height:16px;
    line-height: 20px;
    border:1px solid #262A2C;
    border-radius:50%;
    outline:none;
    vertical-align: middle;
    cursor: pointer;
}
input[type=radio] + label{
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
    color: #262A2C;
    letter-spacing: -1px;
    font-weight: 300;
    margin: 0 0 0 16px;
    padding: 0;
    vertical-align: middle;
    cursor: pointer;
}
input[type='radio']:hover {
    /* box-shadow:0 0 5px 0px orange inset; */
    border: 1px solid #00AAFF;
}
input[type='radio']:before {
    content:'';
    display:block;
    width:100%;
    height:100%;
    border-radius:50%;
    padding: 0;
    margin: 0;
}
input[type='radio']:checked {
    border: 1px solid #00AAFF;
}
input[type='radio']:checked:before {
    border: 3px solid #FFFFFF;
    background: #00AAFF;
}

/* input[type=radio]{
    width: 0;
    margin: 0;
    padding: 0;
    font-size: 1em;
    opacity: 0;
}
input[type=radio] + label{
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
    color: #262A2C;
    letter-spacing: -1px;
    font-weight: 300;
}
input[type=radio] + label > span{
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: 0 16px 3px 0;
  border: 1px solid #262A2C;
  border-radius: 4px;
  background: #FFFFFF;
  vertical-align: bottom;
} */
</style>