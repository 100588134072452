<template>
  <div class="d-flex justify-content-between align-items-center w-100 mt-2 belowNavbar">
    <div>
      <input id="fileUpload" type="file" multiple hidden @change="choosFile">
      <input id="folderUpload" type="file" hidden @change="chooseFolder" webkitdirectory mozdirectory>
      <NewFile :filetype="filetype" />
      <Profile/>
      <Password/>
      <EditProfile/>
      <DeleteAccount/>
      <EndedSession/>
      <ModalMultipleCopy/>
      <b-modal id="modalContainer" :hide-footer="true" :hide-header="true" centered title="BootstrapVue">
        <newFolder/>
      </b-modal>
    </div>
    <div class="w-100 mb-3">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="ml-2">
          <Breadcumb v-if="showBreadCumb"/>
          <h4 class="margin-left-5 mt-2 pt-3 pb-2 default-text-style bold-normal" v-else>{{state == 'storage' ? subTitle : 'Group Files'}}</h4>
        </div>
        <div class="mt-3" v-if="success || error">
          <b-alert  variant="success" show  v-show="success">
            <div class="d-flex justify-content-between align-items-center">
                <span class="px-4">{{message}}</span>
                <span class="cursor-pointer px-2" @click="closeAlertS()">
                  <i class="bx bx-x font-size-16"></i>
                </span>
            </div>
          </b-alert>
          <b-alert  variant="danger" show v-show="error">
                <span class="px-4">{{message}}</span>
                <span class="cursor-pointer px-2" @click="closeAlertE()">
                  <i class="bx bx-x font-size-16"></i>
                </span>
          </b-alert>
        </div>
      </div>
      <div class="row d-flex justify-content-between">
        <div class="mx-2 d-flex justify-content-start align-items-center">
          <div class="mx-1 pr-3" v-if="canAccess">
              <b-dropdown text="New +" variant="primary" class="btn-dropdown blue-buttons active menu-popup">
              <template #button-content>
                <img src="@/assets/images/icon/Plus.svg"  alt height="80%" />
                <span class="default-text-style ml-2"><span class="text-white">New</span></span>
              </template>
                <b-dropdown-item href="#" @click="uploadFile()" class="">
                      <img src="@/assets/images/icon/Etc.svg" alt height="100%" />
                      <span class="ml-3">Upload File</span>
                </b-dropdown-item>
                <b-dropdown-item href="#" v-if="state == 'workgroup' && selectedWorkgroup.type != 'PRIVATE'" @click="uploadFolder()">
                      <img src="@/assets/images/icon/Folder.svg" alt height="100%" />
                      <span class="ml-3">Upload Folder</span>
                </b-dropdown-item>
                <b-dropdown-item href="#" v-else-if="state == 'storage'" @click="uploadFolder()">
                      <img src="@/assets/images/icon/Folder.svg" alt height="100%" />
                      <span class="ml-3">Upload Folder</span>
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                
                <b-dropdown dropright class="nested-dd" id="dropdown-2">
                  <template #button-content>
                    <div class="d-flex justify-content-between">
                      <div>
                        <img src="@/assets/images/icon/addFile.svg" alt height="100%" class="margin-9px"/>
                        <span class="ml-3">Create File</span>
                      </div>
                      <div class="margin-top-dd-bn ml-4">
                        <img src="@/assets/images/icon/chevLeft.svg" alt="" class="">
                      </div>
                    </div>
                  </template>
                  <b-dropdown-item-button @click="createFile('.docx')">
                        <img src="@/assets/images/icon/Docs.svg" alt height="100%" />
                        <span class="ml-3">Document</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button  @click="createFile('.xlsx')">
                        <img src="@/assets/images/icon/XLS.svg" alt height="100%" />
                        <span class="ml-3">Spreedsheet</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button  @click="createFile('.pptx')">
                        <img src="@/assets/images/icon/PPT.svg" alt height="100%" />
                        <span class="ml-3">Presentation</span>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button @click="createFile('.txt')">
                        <img src="@/assets/images/icon/TXT.svg" alt height="100%" />
                        <span class="ml-3">Text</span>
                  </b-dropdown-item-button>
                </b-dropdown>
                <b-dropdown-item-button v-if="state == 'workgroup' && selectedWorkgroup.type != 'PRIVATE'" v-b-modal.modalContainer>
                      <img src="@/assets/images/icon/Folder.svg" alt height="100%" />
                      <span class="ml-3">Create Folder</span>
                </b-dropdown-item-button>
                <b-dropdown-item-button v-else-if="state == 'storage'" v-b-modal.modalContainer>
                      <img src="@/assets/images/icon/Folder.svg" alt height="100%" />
                      <span class="ml-3">Create Folder</span>
                </b-dropdown-item-button>
              </b-dropdown>
          </div>
          <div class="pl-3 border-left d-flex" v-if="selectedField.length > 0 && canAccess">
            <b-button v-b-modal.modal-multiple-copy class="btn base-btn btn-storage mx-2"><span class="default-text-style">Copy</span></b-button>
            <!-- <button class="btn base-btn btn-storage mx-2" @click="copy()">
                <span class="default-text-style">Copy</span>
            </button> -->
            <button v-if="state == 'workgroup' && selectedWorkgroup.type != 'PRIVATE'" class="btn base-btn btn-storage mx-2" @click="move()">
                <span class="default-text-style">Move</span>
            </button>
            <button v-else-if="state == 'storage'" class="btn base-btn btn-storage mx-2" @click="move()">
                <span class="default-text-style">Move</span>
            </button>
            <!-- <button class="btn base-btn btn-storage mx-2" @click="downloadMultiple()" v-if="folderOnSelected.length < 1">
                <span class="default-text-style">Download</span>
            </button> -->
            <button class="btn base-btn btn-storage mx-2" @click="deleteMultiple()">
                <span class="default-text-style">Delete</span>
            </button>
          </div>
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <div class="">
            <b-button   v-if="subTitle != 'Edit Password'" variant="outline-secondary" @click="changeView()" class="mr-4 btn-refresh d-lg-block">
              <!-- <i class="bx bx-chevron-down font-size-16 ml-4 "></i> -->
              <img v-if="grid" src="@/assets/images/icon/fi-rr-apps.svg" class="pb-1" alt height="100%" />
              <img v-else src="@/assets/images/icon/fi-rr-menu-burger.svg" class="pb-1" alt height="100%" />
              <span class="ml-1 default-text-style"> {{grid ? "Grid" : "List"}}</span>
            </b-button>
          </div>
          <div class="mr-3">
            <b-button   v-if="subTitle != 'Edit Password'" variant="outline-secondary" @click="refresh()" class="mr-4 btn-refresh hover-blue  d-none d-lg-block">
              <img src="@/assets/images/icon/fi-rr-refresh.svg" class="pb-1" alt height="100%"/>
              <span class="ml-2 default-text-style">Refresh</span>
            </b-button>
          </div>
        </div>

      </div>
    </div>
        </div>
</template>

<script>
import Breadcumb from "../components/widgets/breadcumb";
import newFolder from "../components/widgets/newFolder.vue";
import NewFile from "../components/widgets/newFile.vue";
import Profile from "../components/widgets/profile.vue";
import Password from "../components/widgets/editPassword.vue";
import EditProfile from "../components/widgets/editProfile.vue";
import DeleteAccount from "./deleteAccount.vue";
import EndedSession from "../components/endedSession.vue";
import ModalMultipleCopy from "../components/modalMultipleCopy.vue"

export default {
  name: 'BelowNavbar',
  data() {
    return {
      showDismissibleAlert : true,
      landing : process.env.VUE_APP_LANDING,
      filetype: ".txt",
    }
  },
  components: {
    newFolder,
    NewFile,
    Breadcumb,
    Profile,
    Password,
    EditProfile,
    DeleteAccount,
    EndedSession,
    ModalMultipleCopy
  },
  computed: {
    listingsShare: {
      //getter
      get: function () {
        let data = this.$store.getters['share/items']
        return data
      },
      //setter
      set: function (newValue) {
        this.$store.commit("share/SET_ITEMS",newValue)
      }
    },
    listings: {
      // getter
      get: function () {
        var data = this.$store.getters[this.$store.getters['state/state']+'/recentList'];
        return data.filter(v=>!v.isFolder)
      },
      // setter
      set: function (newValue) {
        this.$store.commit(this.$store.getters['state/state']+"/SET_RECENTLIST",newValue);
      }
    },
    nama() {
          return this.$store.getters['user/fullname']
    },
    message() {
          var errorMessage = this.$store.getters['error/message']
          if(errorMessage == 'Request Not Valid x-agent x-platform not same' || errorMessage == 'agent required'){
            this.$root.$emit('bv::show::modal', 'ended-session')
          }
          return errorMessage
    },
    success() {
          return this.$store.getters['error/success']
    },
    error() {
          return this.$store.getters['error/error']
    },
    showBreadCumb() {
          // return this.$store.getters['storage/showBreadcumb']
          return this.$store.getters[this.state+'/showBreadcumb']
    },
    subTitle() {
          return this.$store.getters['storage/subTitle']
    },
    selectedField() {
          return this.$store.getters['storage/selected']
    },
    folderOnSelected(){
      return this.selectedField.filter((v)=>v.isFolder);
    },
    grid(){
        return this.$store.getters['storage/gridView'];
    },
    state(){
        return this.$store.getters['state/state'];
    },
    selectedWorkgroup(){
        return this.$store.getters['workgroup/workgroup'];
    },
    showDetails(){
        return this.$store.getters['workgroup/showParticipants'];
    },
    canAccess(){
        var id = this.$store.getters['user/id'];
        var group = this.$store.getters['workgroup/workgroup'];
        var st = this.$store.getters['state/state'];
        if(st == "storage"){
          return true
        }else{
          if(id == group.owner_id){
            return true;
          }else{
            return group.access_type != "View";
          }
        }
    },
    originalSize(){
      return this.$store.getters['user/size_directory'];
    },
    originalUsed(){
      return this.$store.getters['user/total_used'];
    },
  },
  methods: {
    downloadMultiple(){
      var onlyFiles = this.selectedField.filter((v)=>!v.isFolder);
      var data = {
          directory : this.$store.getters['user/directory'],
          list_id : onlyFiles.map((v)=>{
            return {id:v._id}
          })
      }
      this.$store.dispatch("storage/multipleDownload",data);

    },
    deleteMultiple(){
        this.$root.$emit('bv::show::modal', 'modaltoTrash');
    },
    move(){
        this.$root.$emit('bv::show::modal', 'modalMove');
    },
    copy(){
        this.$root.$emit('bv::show::modal', 'modalCopy');
    },
    changeView(){
        this.$store.commit("storage/SET_SHOW_GRID",!this.grid);
    },
    closeAlertNE(){
      this.$root.$emit('bv::show::modal', 'ended-session')
    },
    closeAlertE(){
        this.$store.commit("error/SET_MESSAGE","");
        this.$store.commit("error/SET_ERROR",false);
    },
    closeAlertS(){
        this.$store.commit("error/SET_MESSAGE","");
        this.$store.commit("error/SET_SUCCESS",false);
    },
    sliceChar(str){
      return str.length > 16 ? str.substring(0,16)+" ..." : str;
    },
    refresh(){
      if(this.subTitle == "Recent Files"){
        this.$store.dispatch(this.state+"/recent")
      }else if(this.subTitle == "Share With Me"){
        this.$store.commit("share/SET_SHOWFILE",false);
        this.$store.dispatch("share/listing")
      }else if(this.subTitle == "Trash"){
        this.$store.dispatch("storage/listing",1);
      }
      else{
        // this.$store.dispatch(this.state+"/listing",this.showBreadCumb ? "" : '1')
        this.$store.dispatch(this.state+"/listing")
      }
    },
    search(){
      if(this.subTitle == "Recent Files"){
        this.$store.dispatch(this.state+"/recent").then(() => {
          this.listings = this.listings.filter((file) => {
          return file.file_name.match(this.keyword)
          })
        })
      }
      // }else
      if(this.subTitle == "Share With Me"){
        this.$store.commit("share/SET_SHOWFILE",false);
        this.$store.dispatch("share/listing").then(() => {
          this.listingsShare = this.listingsShare.filter((file) => {
          return file.file[0].file_name.toLowerCase().match(this.keyword)
          })
        })
      }
      else{
        this.$store.dispatch(this.state+"/listing",this.showBreadCumb ? "" : '1')
      }
      if(this.keyword.length >= 4){
          if(this.subTitle == "Share With Me"){
            this.$store.commit("share/SET_SHOWFILE",false);
            this.$store.commit("share/SET_KEYWORD",this.keyword);
            this.$store.dispatch("share/listing").then(() => {
              this.listingsShare = this.listingsShare.filter((file) => {
              return file.file[0].file_name.toLowerCase().match(this.keyword)
              
              })
            })
          }
          else{
            this.$store.commit(this.state+"/SET_KEYWORD",this.keyword);
            this.$store.dispatch(this.state+"/listing")
          }
      }else if(this.keyword == ""){
          this.$store.commit(this.state+"/SET_KEYWORD",this.keyword);
          this.$store.dispatch(this.state+"/listing")
      }
    },
    searchEnter(){
        if(this.subTitle == "Share With Me"){
          this.$store.commit("share/SET_SHOWFILE",false);
          this.$store.commit("share/SET_KEYWORD",this.keyword);
          this.$store.dispatch("share/listing")
        }
        else{
          this.$store.commit(this.state+"/SET_KEYWORD",this.keyword);
          this.$store.dispatch(this.state+"/listing")
        }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    createFile(type){
      this.filetype = type;
      this.$root.$emit('bv::show::modal', 'modalCreate');
    },
    uploadFile(){
      document.getElementById("fileUpload").click()
    },
    uploadFolder(){
      document.getElementById("folderUpload").click()
    },
    checkNested(list){
      return new Promise((resolve,reject)=>{
        var haveNested = false;
        for (let i = 0; i < list.length; i++) {
            haveNested = list[i].webkitRelativePath.split("/").length > 2
        }
        if(haveNested){
          reject(haveNested);
        }else{
          resolve(haveNested);
        }
      });
    },
    chooseFolder(){
      let files = document.getElementById("folderUpload").files;
      if(files.length <= 100){
        var params = {
          parent_id: this.$store.getters[this.state+"/parent_id"],
          file_name: files[0].webkitRelativePath.split("/")[0],
          extension: '',
          size: 0
        }
        if(this.state == "workgroup"){
          params.workgroup_id = this.selectedWorkgroup._id
          params.owner_id = this.selectedWorkgroup.owner_id
        }
        this.$store.dispatch(this.state+"/beforeUploaded", params)
        .then(() => {
          this.checkNested(files)
          .then(() => {
            var self = this;
            document.getElementById("uploadLoading").style.display = "block";
            let promise = [];
            var filesData = {
              id : (Math.random() + 1).toString(36).substring(3),
              name : files[0].webkitRelativePath.split("/")[0],
              done : false,
              status : 2,//1=prepare,2=uploading,3=done,4=failed,
              size : 0,
              type : 0,
              percentage : 0,
              error : "",
              isFolder : true,
              total : files.length,
              uploaded : 0
            }
            this.$store.commit("upload/ADD_FILES",filesData);
            var sumFileSize = 0
            for (let i = 0; i < files.length; i++){
              sumFileSize = files[i].size + sumFileSize
            }
            var sumUploadUsed = this.originalUsed + sumFileSize
            if(sumUploadUsed <= this.originalSize){
              for (let f = 0; f < files.length; f++) {
                // sumFileSize = files[f].size + sumFileSize
                if(files[f].size > 0){
                  var metadata = {
                    filename: files[f].name,
                    filetype: files[f].name.split('.').pop() == '' || files[f].name.split('.').pop() == files[f].name ? 'txt' : files[f].name.split('.').pop(),
                    directory: self.$store.getters["user/directory"],
                    path: files[f].webkitRelativePath,
                    type: "2"
                  }
                  promise.push(
                    this.$store.dispatch(this.state+"/uploadTus",{
                      file : files[f],
                      metadata : metadata,
                      filesData : filesData,
                      isFolder : true
                    })
                  );
                }
              }
              Promise.all(promise).then((data)=>{
              var paramUpload = {
                parent_id : this.$store.getters[this.state+"/parent_id"],
                data : data
              }
              if(this.state == "workgroup"){
                paramUpload = {
                  workgroup_id : this.selectedWorkgroup._id,
                  owner_id : this.selectedWorkgroup.owner_id,
                  parent_id : this.$store.getters[this.state+"/parent_id"],
                  data : data
                }
              }
              this.$store.dispatch(this.state+"/uploadFolder",paramUpload, filesData.id)
              .then((success)=>{
                this.$store.commit("upload/STATUS",{id:filesData.id,status:3,error:success});
                this.$store.commit("upload/DONE",{id:filesData.id,done:true});
              })
              .catch((error)=>{
                this.$store.commit("upload/STATUS",{id:filesData.id,status:4,error: error.response ? error.response.data : error});
              })
            });
            } else {
              filesData.done = true
              filesData.status = 4
              filesData.error = "Not enough storage"
              // this.$store.commit("upload/ADD_FILES",filesData);
              document.getElementById("uploadLoading").style.display = "block";
            }
            
          })
  
          .catch(() => {
            var filesData = {
              id : (Math.random() + 1).toString(36).substring(3),
              name : 'Test',
              done : false,
              status : 4,//1=prepare,2=uploading,3=done,4=failed,
              size : 0,
              type : 0,
              percentage : 0,
              error : "Cannot upload nested folder",
              isFolder : true,
              total : files.length,
              uploaded : 0
            }
            this.$store.commit("upload/ADD_FILES",filesData);
            document.getElementById("uploadLoading").style.display = "block";
          })
        })
        .catch((err) => {
          this.$toasted.show(`${err.data.display_message}`,{ 
            theme: "toasted-primary", 
            position: "bottom-center", 
            duration : 2000
          })
        })
      }
      else{
        var filesData = {
          id : (Math.random() + 1).toString(36).substring(3),
          name : 'Test',
          done : false,
          status : 4,//1=prepare,2=uploading,3=done,4=failed,
          size : 0,
          type : 0,
          percentage : 0,
          error : "Cannot upload more than 100 files",
          isFolder : true,
          total : files.length,
          uploaded : 0
        }
        this.$store.commit("upload/ADD_FILES",filesData);
        document.getElementById("uploadLoading").style.display = "block";
      }
    },
    choosFile(){
        let files = document.getElementById("fileUpload").files;
        document.getElementById("uploadLoading").style.display = "block";
        let promise = [];
        for (let i = 0; i < files.length; i++) {
          if(files.length <= 100){
            var params = {
              parent_id: this.$store.getters[this.state+"/parent_id"],
              file_name: files[i].name,
              extension: files[i].name.split('.').pop() == files[i].name ? 'txt' : files[i].name.split('.').pop(),
              size: files[i].size
            }
        if(this.state == "workgroup"){
          params.workgroup_id = this.selectedWorkgroup._id
          params.owner_id = this.selectedWorkgroup.owner_id
        }
            this.$store.dispatch(this.state+"/beforeUploaded", params)
            .then(() => {
              if(files[i].size <= 0){
                var filesData = {
                  id : (Math.random() + 1).toString(36).substring(2),
                  name : files[i].name,
                  done : true,
                  status : 4,//1=prepare,2=uploading,3=done,4=failed,
                  size : files[i].size,
                  type : files[i].type == '' ? 'txt' : files[i].type,
                  percentage : 0,
                  error : "File not contain any data, Please create file on jaybod!",
                  isFolder : false,
                  total : 1,
                  uploaded : 0
                }
                this.$store.commit("upload/ADD_FILES",filesData);
              }else{
                filesData = {
                  id : (Math.random() + 1).toString(36).substring(2),
                  name : files[i].name,
                  done : false,
                  status : 2,//1=prepare,2=uploading,3=done,4=failed,
                  size : files[i].size,
                  type : files[i].type == '' ? 'txt' : files[i].type,
                  percentage : 0,
                  error : "",
                  isFolder : false,
                  total : 1,
                  uploaded : 0
                }
                this.$store.commit("upload/ADD_FILES",filesData);
                var metadata = {
                      filename: files[i].name,
                      filetype: files[i].name.split('.').pop() == '' || files[i].name.split('.').pop() == files[i].name ? 'txt' : files[i].name.split('.').pop()
                }
                if(this.originalUsed + filesData.size <= this.originalSize){
                  promise.push(
                    this.$store.dispatch(this.state+"/uploadTus",{
                      file : files[i],
                      metadata : metadata,
                      filesData : filesData,
                      isFolder : false
                    })
                  );
                } else{
                  filesData.done = true
                  filesData.status = 4
                  filesData.error = 'Storage has been reach the limit'
                  // this.$store.commit("error/SET_MESSAGE","Storage has been reach the limit");
                  // this.$store.commit("error/SET_ERROR",true);
                }
              } 
            })
            .catch((err) => {
              this.$toasted.show(`${err.data.display_message}`,{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : 2000
              })
            })
          }else{
            this.$store.commit("error/SET_MESSAGE","Cannot upload more than 100 files");
            this.$store.commit("error/SET_ERROR",true);
          }
        }
        Promise.all(promise).then(()=>{
        });
    },
    openDetailWorkgroup(){
      this.$store.dispatch("workgroup/showDetails");
    }
  }
}
</script>

<style>
#dropdown-2 button{
  background: transparent !important;
  color: #212529 !important;
  font-size: 13px !important;
  font-weight: 400;
  letter-spacing: -0.2px;
}
#dropdown-2 button:hover{
  background: #E9F5FB !important;
  color: #212529 !important;
  font-size: 13px !important;
  font-weight: 400;
  letter-spacing: -0.2px;
  border-radius: 4px;
}
#dropdown-3 button{
  background: transparent !important;
}
#dropdown-3 button:hover{
  background-color: #E9F5FB !important;
  color: #212529 !important;
  font-size: 13px !important;
  font-weight: 400;
  letter-spacing: -0.2px;
  border-radius: 4px;
}
#dropdown-2 ul{
  transform: translate3d(185px, -50px, 0px) !important;
}
.margin-9px{
    margin-left: 9px;
}
.margin-top-dd-bn{
    margin-top: 1px;
}
</style>